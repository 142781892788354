var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"header"},[(_vm.medicalbusiness && _vm.medicalbusiness.logo)?_c('a',[_c('img',{staticClass:"logo",attrs:{"src":_vm.medicalbusiness.logo}})]):_vm._e(),_c('div',{staticClass:"hamburger-menu"},[_c('input',{attrs:{"type":"checkbox","id":"menu-btn-check"}}),_vm._m(0),_c('div',{staticClass:"menu-content"},[_c('ul',[_c('li',[_c('router-link',{attrs:{"to":{
                name: _vm.routeName.TREATMENT_RESERVATION,
                params: { medicalBusinessId: _vm.medicalbusinessId }
              }},nativeOn:{"click":function($event){return _vm.menuClick($event)}}},[_vm._v("診察予約")])],1),_c('li',[_c('router-link',{attrs:{"to":{
                name: _vm.routeName.TERMS_OF_SERVICE,
                params: { medicalBusinessId: _vm.medicalbusinessId }
              }},nativeOn:{"click":function($event){return _vm.menuClick($event)}}},[_vm._v("利用規約")])],1),_c('li',[_c('router-link',{attrs:{"to":{
                name: _vm.routeName.PRIVACY_POLICY,
                params: { medicalBusinessId: _vm.medicalbusinessId }
              }},nativeOn:{"click":function($event){return _vm.menuClick($event)}}},[_vm._v("プライバシーポリシー")])],1)])])])])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('label',{staticClass:"menu-btn",attrs:{"for":"menu-btn-check"}},[_c('span')])}]

export { render, staticRenderFns }