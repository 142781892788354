import axios from 'axios'
import vm from '../main'

const service = axios.create({
  baseURL: process.env.VUE_APP_BASE_API, // url = base url + request url
  timeout: 10000
  // withCredentials: true // send cookies when cross-domain requests
})

// Request interceptors
service.interceptors.request.use(
  config => {
    // Add X-Access-Token header to every request, you can add other custom headers here
    // if (UserModule.token) {
    //   config.headers.Authorization = 'Bearer ' + UserModule.token
    // }
    return config
  },
  error => {
    Promise.reject(error)
  }
)

// Response interceptors
service.interceptors.response.use(
  response => {
    if (response.status >= 200 && response.status < 300) {
      return Promise.resolve(response)
    } else {
      return Promise.reject(new Error('Error'))
    }
  },
  error => {
    if (
      error.response.status === 401 ||
      error.response.config.method === 'get'
    ) {
      vm.$router.push({
        path: '/error'
      })
    }
    return Promise.reject(error)
  }
)
export default service
