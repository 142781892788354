var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"footer"},[_c('ul',[_c('li',[_c('router-link',{attrs:{"to":{
          name: _vm.routeName.TREATMENT_RESERVATION,
          params: { medicalBusinessId: _vm.medicalbusinessId }
        }}},[_vm._v("診察予約")])],1),_c('li',[_c('router-link',{attrs:{"to":{
          name: _vm.routeName.TERMS_OF_SERVICE,
          params: { medicalBusinessId: _vm.medicalbusinessId }
        }}},[_vm._v("利用規約")])],1),_c('li',[_c('router-link',{attrs:{"to":{
          name: _vm.routeName.PRIVACY_POLICY,
          params: { medicalBusinessId: _vm.medicalbusinessId }
        }}},[_vm._v("プライバシーポリシー")])],1)]),_c('p',{staticClass:"copyright"},[_vm._v("Copyright © March All rights reserved.")])])}
var staticRenderFns = []

export { render, staticRenderFns }