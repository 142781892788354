import request from '@/utils/request'
import { MedicalBusiness } from '@/interface/api'

const prefix = '/api/v0/customer/medical-businesses'

export const retrieveMedicalBusiness = (
  medicalBusinessId: string
): Promise<MedicalBusiness> =>
  request({
    url: `${prefix}/${medicalBusinessId}/`,
    method: 'get'
  }).then(response => response.data.data.attributes)
